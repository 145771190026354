import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Modal, Radio, Select, Spin} from 'antd';
import axiosInstance from "../api";
import {requiredField} from "../util";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useForm} from "antd/lib/form/Form";
import BackTitle from "../common/BackTitle";


export default function EditListingElement() {
    const [categories, setCategories] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = useForm()
    const {id, elementId} = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        setDataLoading(true)
        axiosInstance.get(`listing/${id}/element/${elementId}`)
            .then(({data}) => form.setFieldsValue({...data, category: data.category.id}))
            .finally(() => setDataLoading(false))
        axiosInstance.get("listingElementCategory")
            .then(({data}) => setCategories(data))
    }, []);

    const onFinish = form => {
        setLoading(true)
        axiosInstance.put(`listing/${id}/element/${elementId}`, form)
            .then(() => {
                message.success("Elemento modificato con successo")
            })
            .finally(() => {
                setLoading(false)
                navigate(-1)
            });
    }


    return (
        <Spin spinning={dataLoading}>
            <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
                <BackTitle padding={0} title={"Modifica elemento listino"}/>
                <Form id={"new-el"} layout={"vertical"} onFinish={onFinish} form={form}>
                    <Form.Item label={"Categoria"} name={"category"} rules={[requiredField]}>
                        <Select options={categories.map(x => ({label: x.name, value: x.id}))}/>
                    </Form.Item>
                    <Form.Item label={"Voce"} name={"refId"} rules={[requiredField]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Unità di misura"} name={"measureUnit"} rules={[requiredField]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Prezzo base/Punti"} name={"basePrice"} rules={[requiredField]}>
                        <InputNumber min={0}/>
                    </Form.Item>
                    <Form.Item label={"Coefficiente"} name={"applyMultiplier"} rules={[requiredField]}>
                        <Radio.Group>
                            <Radio value={true}>Applica</Radio>
                            <Radio value={false}>Non applicare</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item style={{textAlign: "end"}}>
                        <Button type={"primary"} htmlType={"submit"} loading={loading}>Salva</Button>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    );
}