import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { requiredField } from "../util";
import { useNavigate, useParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { useForm } from "antd/lib/form/Form";

export default function EditOrder() {
    const { orderId } = useParams();

    const [cities, setCities] = useState([]);
    const [multipliers, setMultipliers] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [timer, setTimer] = useState();
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(false);
    const navigate = useNavigate();
    const [contract, setContract] = useState({});
    const [form] = useForm();

    useEffect(() => {
        setInitLoading(true);
        axiosInstance
            .get(`order/${orderId}`)
            .then(({ data }) => {
                form.setFieldsValue({
                    ...data,
                    multiplier: data.multiplier?.id,
                    city: data.city.id,
                });
                return data;
            })
            .then((data) => {
                handleSearchCityChange(data.city.name);
                setContract(data.contract);
                return axiosInstance.get(
                    `customer/${data.contract.customer.id}/contract/${data.contract.id}/multiplier`,
                );
            })
            .then(({ data }) => setMultipliers(data))
            .finally(() => setInitLoading(false));

    }, []);

    const handleSearchCityChange = (text) => {
        if (timer) clearTimeout(timer);
        if (text.length > 2) {
            setLoadingCities(true);
            const timeout = setTimeout(() => {
                axiosInstance
                    .get(`city?q=${text}`)
                    .then(({ data }) => setCities(data))
                    .finally(() => setLoadingCities(false));
            }, 500);
            setTimer(timeout);
        }
    };

    const onFinish = (form) => {
        setLoading(true);
        axiosInstance
            .put(`order/${orderId}`, form)
            .then(() => navigate(-1))
            .then(() => {
                message.success("Commessa creata con successo");
            })
            .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={initLoading}>
            <BackTitle padding={0} title={"Modifica commessa"} />
            <Form onFinish={onFinish} layout={"vertical"} style={{ marginTop: 16 }} form={form}>
                <Form.Item label={"Titolo"} name={"title"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                {contract?.requireMultiplier && (
                    <Form.Item
                        label={"Coefficiente valorizzazione"}
                        name={"multiplier"}
                        rules={[requiredField]}
                    >
                        <Select
                            options={multipliers.map((x) => ({ label: x.name, value: x.id }))}
                        />
                    </Form.Item>
                )}
                <Form.Item label={"Indirizzo"} rules={[requiredField]} name={"address"}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Città"} rules={[requiredField]} name={"city"}>
                    <Select
                        showSearch
                        onSearch={handleSearchCityChange}
                        filterOption={false}
                        notFoundContent={
                            loadingCities ? "Caricamento in corso..." : "Nessun elemento"
                        }
                        loading={loadingCities}
                        options={cities.map((x) => ({ label: x.name, value: x.id }))}
                    />
                </Form.Item>
                <Form.Item style={{ textAlign: "end" }}>
                    <Button htmlType={"submit"} loading={loading} type={"primary"}>
                        Salva
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
