import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import axiosInstance from "../api";
import {
    AutoComplete,
    Checkbox,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Select,
    Spin,
} from "antd";
import { filterOption, requiredField } from "../util";

export default function EditSectionItem({ open, itemId, onFinish, onCancel }) {
    const { orderId, accountingId } = useParams();
    const [initLoading, setInitLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isExtra, setIsExtra] = useState(false);
    const [listingElements, setListingElements] = useState([]);
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);
    const [form] = useForm();
    const [suggestions, setSuggestions] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (itemId && open) {
            document.body.style.overflowY = "scroll";
            setInitLoading(true);
            axiosInstance
                .get(`/accountingItem/${itemId}`)
                .then(({ data }) => {
                    form.setFieldsValue({
                        ...data,
                        isExtra: data.type === "EXTRA",
                        teamId: data.team?.id,
                        listingElement: data.listingElement?.id,
                    });
                    setIsExtra(form.getFieldValue("isExtra"));
                    return axiosInstance
                        .get(`order/${orderId}/listing/element`)
                        .then(({ data }) => setListingElements(data));
                })
                .finally(() => setInitLoading(false));
            axiosInstance
                .get(`accountingItem/sectionSuggestion`, {
                    params: { accountingId: Number(accountingId) },
                })
                .then(({ data }) => {
                    setSuggestions(data);
                    setOptions(data.map((x) => ({ value: x })));
                });

            axiosInstance
                .get("team")
                .then(({ data }) => setTeams(data.map((x) => ({ label: x.name, value: x.id }))));
        }
    }, [itemId, open]);

    const onFormFinish = (form) => {
        setLoading(true);
        axiosInstance
            .put(`accountingItem/${itemId}`, form)
            .then(() => {
                message.success("Elemento modificato");
            })
            .then(onFinish)
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "form", htmlType: "submit", loading: loading }}
            title={"Modifica voce"}
            okText={"Salva"}
        >
            <Spin spinning={initLoading}>
                <div>
                    <Form
                        id={"form"}
                        form={form}
                        onFinish={onFormFinish}
                        layout={"vertical"}
                        style={{ marginTop: 16 }}
                    >
                        <Form.Item label={"Tipo"} name={"isExtra"} valuePropName={"checked"}>
                            <Checkbox
                                checked={isExtra}
                                onChange={({ target }) => setIsExtra(target.checked)}
                            >
                                Voce extra listino
                            </Checkbox>
                        </Form.Item>
                        {!isExtra && (
                            <Form.Item
                                label={"Voce"}
                                name={"listingElement"}
                                rules={[requiredField]}
                            >
                                <Select
                                    filterOption={filterOption}
                                    showSearch
                                    options={listingElements.map((x) => ({
                                        label: x.refId + " - " + x.description,
                                        value: x.id,
                                    }))}
                                />
                            </Form.Item>
                        )}
                        {isExtra && (
                            <Form.Item
                                label={"Descrizione"}
                                name={"description"}
                                rules={[requiredField]}
                            >
                                <Input />
                            </Form.Item>
                        )}
                        <Form.Item label={"Tratta (Opzionale)"} name={"section"}>
                            <AutoComplete
                                options={options}
                                onSearch={(x) =>
                                    setOptions(
                                        suggestions
                                            .filter((y) =>
                                                y.toLowerCase().includes(x.toLowerCase()),
                                            )
                                            .map((x) => ({ value: x })),
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item label={"Quantità"} name={"quantity"} rules={[requiredField]}>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label={"Note"} name={"notes"}>
                            <Input />
                        </Form.Item>

                        <Form.Item label={"Squadra"} name={"teamId"} rules={[requiredField]}>
                            <Select
                                allowClear
                                showSearch
                                filterOption={filterOption}
                                options={teams}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </Modal>
    );
}
