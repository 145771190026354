import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Spin } from "antd";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";
import Backheader from "../common/BackTitle";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

export default function Customersheet() {
    const [customers, setCustomers] = useState([]);
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState([]);
    const gridRef = useRef();

    const columns = [
        {
            field: "order.name",
            headerName: "Commessa",
            filter: "agTextColumnFilter",
            flex: 2,
        },
        {
            field: "order.address",
            headerName: "Indirizzo",
            filter: "agTextColumnFilter",
            flex: 2,
        },
        {
            field: "accounting.month",
            headerName: "Competenza",
            sortable: false,
            valueFormatter: (params) => dayjs(params.value).format("MM/YYYY"),
            flex: 1,
        },
        {
            field: "accounting.name",
            headerName: "Foglio Contabilità",
            filter: "agTextColumnFilter",
            flex: 1,
        },
        {
            field: "section",
            filter: "agTextColumnFilter",
            headerName: "Tratta",
            flex: 1,

        },
        {
            headerName: "Voce",
            filter: "agTextColumnFilter",
            field: "refId",
            flex: 1,
        },
        {
            headerName: "Descrizione",
            filter: "agTextColumnFilter",
            field: "description",
            flex: 1,
        },
        {
            field: "quantity",
            headerName: "Q.ta",
            type: "rightAligned",
            valueFormatter: (params) => params.data.quantity.toLocaleString("it-IT"),
            flex: 1,
        },
        {
            field: "paidQuantity",
            headerName: "Q.ta saldata",
            type: "rightAligned",
            editable: true,
            valueFormatter: (params) => params.data.paidQuantity.toLocaleString("it-IT"),
            flex: 1,
        },
        {
            field: "unpaidQuantity",
            headerName: "Q.ta da saldare",
            type: "rightAligned",
            cellStyle: (params) => ({ color: params.value > 0 ? "red" : "inherit" }),
            valueFormatter: (params) => params.data.unpaidQuantity.toLocaleString("it-IT"),
            flex: 1,
        },
        {
            field: "price",
            headerName: "Importo",
            type: "rightAligned",
            valueFormatter: (params) =>
                params.data.price
                    ? `€ ${params.data.price?.toLocaleString("it-IT", { minimumFractionDigits: 2 })}`
                    : "",
            flex: 1,
        },
    ];

    useEffect(() => {
        axiosInstance.get("customer").then(({ data }) => setCustomers(data));
    }, []);

    const onFinish = (form) => {
        setDataLoading(true);
        form.startDate = form.month[0].startOf("month").format("YYYY-MM-DD");
        form.endDate = form.month[1].endOf("month").format("YYYY-MM-DD");
        delete form.month;
        axiosInstance
            .get("accountingItem", { params: form })
            .then(({ data }) =>
                setData(
                    data.map((x) => ({
                        ...x,
                        refId: x.listingElement?.refId ?? "EXTRA",
                        description: x.listingElement?.description ?? x.description,
                    })),
                ),
            )
            .finally(() => setDataLoading(false));
    };

    const handleSave = (record, value) => {
        console.log(record);
        setLoading(true);
        axiosInstance
            .patch(`accountingItem/${record.id}`, { paidQuantity: parseInt(value) })
            .then((res) =>
                setData(
                    data.map((x) =>
                        x.id === record.id
                            ? {
                                  ...res.data,
                                  refId: res.data.listingElement?.refId ?? "EXTRA",
                                  description:
                                      res.data.listingElement?.description ??
                                      res.data.description,
                              }
                            : x,
                    ),
                ),
            )
            .finally(() => setLoading(false));
    };

    const updateSummary = () => {
        if (!gridRef.current?.api) return;
        const curData = [];
        gridRef.current.api.forEachNodeAfterFilter((x) => curData.push(x.data));
        const summary = curData.reduce(
            (prev, cur) => ({
                quantity: prev.quantity + cur.quantity,
                paidQuantity: prev.paidQuantity + cur.paidQuantity,
                unpaidQuantity: prev.unpaidQuantity + cur.unpaidQuantity,
            }),
            { quantity: 0, paidQuantity: 0, unpaidQuantity: 0 },
        );
        setSummary([summary]);
    };

    useEffect(() => {
        updateSummary();
    }, [data]);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Backheader backEnabled={false} title="Schede Clienti" padding={0} />
            <p>
                Da questa pagina puoi visualizzare e saldare il dettaglio dei lavori di un cliente
            </p>
            <Form layout="vertical" onFinish={onFinish} style={{ maxWidth: 350 }}>
                <Form.Item label="Cliente" name={"customerId"} rules={[requiredField]}>
                    <Select
                        filterOption={filterOption}
                        showSearch
                        options={customers.map((x) => ({ label: x.name, value: x.id }))}
                    />
                </Form.Item>
                <Form.Item label={"Mese di competenza"} name={"month"} rules={[requiredField]}>
                    <RangePicker picker={"month"} format={"MMMM YYYY"} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={dataLoading}>
                        Cerca
                    </Button>
                </Form.Item>
            </Form>
            {data.length > 0 && (
                <>
                    <Divider orientation="left">Risultati</Divider>

                    <Spin spinning={loading}>
                        <div style={{ width: "100%", height: 700 }} className={"ag-theme-quartz"}>
                            <AgGridReact
                                columnDefs={columns}
                                rowData={data}
                                ref={gridRef}
                                getRowId={(x) => x.data.id}
                                readOnlyEdit={true}
                                onFilterChanged={updateSummary}
                                onCellEditRequest={(event) => {
                                    const val = event.newValue;
                                    const data = event.data;
                                    handleSave(data, val);
                                }}
                                defaultColDef={{
                                    suppressHeaderMenuButton: true,
                                    floatingFilter: true,
                                }}
                                pinnedBottomRowData={summary}
                            />
                        </div>
                    </Spin>
                </>
            )}
        </div>
    );
}
