import BackTitle from "../common/BackTitle";
import {Button, Form, Input, message, Select} from "antd";
import {filterOption, requiredField} from "../util";
import {useEffect, useState} from "react";
import axiosInstance from "../api";
import {useNavigate} from "react-router-dom";


export default function NewContact(){

    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {
        axiosInstance.get('customer')
            .then(({data}) => setCustomers(data))
    }, []);

    const onFinish = (form) =>{
        setLoading(true)
        axiosInstance.post(`contact`,form)
            .then(() => navigate(-1))
            .then(() => {message.success("Contatto creato")})
            .finally(() => setLoading(false))
    }

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <BackTitle title={"Nuovo contatto"} padding={0}/>
        <Form layout={"vertical"} onFinish={onFinish}>
            <Form.Item label={"Nome completo"} name={"fullName"} rules={[requiredField]}>
               <Input/>
            </Form.Item>
            <Form.Item label={"Cliente"} name={"customer"} rules={[requiredField]}>
                <Select showSearch filterOption={filterOption} options={customers.map(x => ({label: x.name, value: x.id}))}/>
            </Form.Item>
            <Form.Item label={"Email"} name={"email"} rules={[{type: "email", required: true, message: 'Email non valida'}]}>
                <Input/>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button htmlType={"submit"} type={"primary"} loading={loading}>Salva</Button>
            </Form.Item>
        </Form>
    </div>
}
