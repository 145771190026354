import {Table} from "antd";
import {useEffect, useState} from "react";
import BackTitle from "../common/BackTitle";
import {useNavigate, useSearchParams} from "react-router-dom";
import axiosInstance from "../api";
import Toolbar from "../common/Toolbar";
import Search from "antd/lib/input/Search";


const columns = [
    {
        key: "N.",
        dataIndex: "id",
        width: 150
    },
    {
        key: 'name',
        dataIndex: 'name',
        width: 200,
        title: 'Nome'
    },
    {
        key: 'description',
        dataIndex: 'description',
        width: 200,
        title: 'Descrizione',
    },
]

export default function Teams() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ?? 0;
    const pageSize = searchParams.get("pageSize") ?? 10;
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [searchText, setSearchText] = useState(searchParams.get('q'));


    const fetchData = () => {
        const params = [...searchParams.entries()].map(([k, v]) => `${k}=${v}`).join('&')
        setSelectedRowKeys([])
        setLoading(true)
        axiosInstance.get(`team?${params}`)
            .then(({data}) => setData(data))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
    }, [searchParams]);

    const deleteRecords = (keys) => {
        setLoading(true)
        axiosInstance.delete(`team?${keys.map(x => `ids=${x}`).join('&')}`)
            .then(() => setSelectedRowKeys([]))
            .then(() => fetchData())
            .finally(() => setLoading(false))
    }


    return <div>
        <BackTitle backEnabled={false} padding={0} title={"Contatti"}/>
        <Search allowClear
                value={searchText}
                style={{width: 200,marginTop: 20}}
                placeholder={'Cerca'}
                onChange={x => setSearchText(x.target.value)}
                onSearch={x => {
                    setSearchText(x)
                    if(x)
                        searchParams.set("q", x)
                    else
                        searchParams.delete("q")
                    setSearchParams(searchParams)
                }}/>
        <Toolbar
            style={{marginTop: 30}}
            selectedKeys={selectedRowKeys}
            addAction={() => navigate('new')}
            editAction={(key) => navigate(`${key}/edit`)}
            deleteAction={deleteRecords}
        />
        <Table
            columns={columns}
            loading={loading}
            size={"small"}
            onChange={(pagination) => {
                searchParams.set("page", "" + (pagination.current - 1))
                searchParams.set("pageSize", "" + pagination.pageSize)
                setSearchParams(searchParams, {replace: true})
            }}
            tableLayout={"fixed"}
            scroll={{x: columns.reduce((acc, x) => acc + x.width, 0)}}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => {
                        setSelectedRowKeys([record.key])
                    }, // click row
                };
            }}
            rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys}}
            pagination={{
                current: Number(page) + 1,
                showTotal: (total) => "Totale: " + total,
                pageSize: pageSize,
                total: data.count ?? 0
            }}
            dataSource={data?.map(x => ({...x, key: x.id})) ?? []}/>

    </div>
}
