import {useEffect, useState} from "react";
import axiosInstance from "../../api";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, message, Popconfirm, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import Toolbar from "../../common/Toolbar";
import {formatDateTime} from "../../util";


const columns = [
    {
        key: 'name',
        dataIndex: 'name',
        width: 150,
        ellipsis: true,
        title: 'Nome',
        render: (name, record) => <Link to={`contracts/${record.id}`}>{name}</Link>
    },
    {
        key: 'listing',
        title: 'Listino',
        dataIndex: 'listing',
        width: 150,
        ellipsis: true,
        render: listing => listing.name
    },
    {
        key: 'requireMultiplier',
        title: 'Richiedi coeff.',
        dataIndex: 'requireMultiplier',
        width: 50,
        ellipsis: true,
        render: x => x ? "Si" : "No"
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: 'Creato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'creationDate',
        dataIndex: 'creationDate',
        title: 'Data creazione',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },
    {
        key: 'updatedBy',
        dataIndex: 'updatedBy',
        title: 'Modificato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'updateDate',
        dataIndex: 'updateDate',
        title: 'Data modifica',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },
]

export default function Contracts() {
    const [contracts, setContracts] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const navigate = useNavigate();
    const {id} = useParams()

    const fetchData = () => {
        setLoading(true)
        axiosInstance.get(`customer/${id}/contract`)
            .then(({data}) => setContracts(data))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
    }, []);

    const deleteSelectedRecords = (keys) => {
        setLoading(true)
        axiosInstance.delete(`customer/${id}/contract?${keys.map(x => `ids=${x}`).join('&')}`)
            .then(() => {
                message.success("Record eliminati")
            })
            .then(() => setSelectedRowKeys([]))
            .then(() => fetchData())
            .finally(() => setLoading(false))
    }

    return <div>
        <Toolbar
            selectedKeys={selectedRowKeys}
            addAction={() => navigate('contracts/new')}
            editAction={(k) => navigate(`contracts/${k}/edit`)}
            deleteAction={deleteSelectedRecords}
        />
        <Table
            loading={loading}
            dataSource={contracts.map(x => ({...x, key: x.id}))}
            tableLayout={"fixed"}
            scroll={{x: columns.reduce((acc, x) => acc + x, 0)}}
            size={"small"}
            columns={columns}
            rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys}}
            pagination={{onChange: () => setSelectedRowKeys([])}}
        />
    </div>
}