import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "antd/lib/form/Form";
import axiosInstance from "../api";
import {Button, Form, Input, message, Spin} from "antd";
import {requiredField} from "../util";
import BackTitle from "../common/BackTitle";


export default function EditSection() {
    const {orderId, accountingId, sectionId, itemId} = useParams()
    const [initLoading, setInitLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [form] = useForm()

    useEffect(() => {
        axiosInstance.get(`order/${orderId}/accounting/${accountingId}/section/${sectionId}`)
            .then(({data}) => form.setFieldsValue(data))
            .finally(() => setInitLoading(false))
    }, []);
    const onFinish = (form) => {
        setLoading(true)
        axiosInstance.put(`order/${orderId}/accounting/${accountingId}/section/${sectionId}`, form)
            .then(() => {
                message.success("Tratta modificata")
            })
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }
    return <Spin spinning={initLoading}>
        <div>
            <BackTitle padding={0} title={"Modifica tratta"}/>
            <Form form={form} onFinish={onFinish} style={{marginTop: 16}} layout={"vertical"}>
                <Form.Item label={"Nome"} name={"name"}>
                    <Input/>
                </Form.Item>
                <Form.Item style={{textAlign: "end"}}>
                    <Button htmlType={"submit"} loading={loading} type={"primary"}>Salva</Button>
                </Form.Item>
            </Form>
        </div>
    </Spin>
}
