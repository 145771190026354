import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { requiredField } from "../util";
import { useNavigate } from "react-router-dom";
import BackTitle from "../common/BackTitle";

export default function NewOrder() {
    const [customers, setCustomers] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [cities, setCities] = useState([]);
    const [multipliers, setMultipliers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedContract, setSelectedContract] = useState();
    const [loadingCities, setLoadingCities] = useState(false);
    const [timer, setTimer] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("customer").then(({ data }) => setCustomers(data));
    }, []);

    useEffect(() => {
        if (selectedCustomer)
            axiosInstance
                .get(`customer/${selectedCustomer}/contract`)
                .then(({ data }) => setContracts(data));
    }, [selectedCustomer]);

    useEffect(() => {
        if (selectedContract)
            axiosInstance
                .get(`customer/${selectedCustomer}/contract/${selectedContract.id}/multiplier`)
                .then(({ data }) => setMultipliers(data));
    }, [selectedContract]);

    const handleSearchCityChange = (text) => {
        if (timer) clearTimeout(timer);
        if (text.length > 2) {
            setLoadingCities(true);
            const timeout = setTimeout(() => {
                axiosInstance
                    .get(`city?q=${text}`)
                    .then(({ data }) => setCities(data))
                    .finally(() => setLoadingCities(false));
            }, 500);
            setTimer(timeout);
        }
    };

    const onFinish = (form) => {
        setLoading(true);
        axiosInstance
            .post("order", form)
            .then(({ data }) => navigate(`/orders/${data.id}`, { replace: true }))
            .then(() => {
                message.success("Commessa creata con successo");
            })
            .finally(() => setLoading(false));
    };
    console.log(selectedContract);
    return (
        <div>
            <BackTitle padding={0} title={"Nuova commessa"} />
            <Form onFinish={onFinish} layout={"vertical"} style={{ marginTop: 16 }}>
                <Form.Item label={"Titolo"} name={"title"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Cliente"} name={"customer"} rules={[requiredField]}>
                    <Select
                        onSelect={setSelectedCustomer}
                        options={customers.map((x) => ({ value: x.id, label: x.name }))}
                    />
                </Form.Item>
                <Form.Item label={"Contratto"} name={"contract"} rules={[requiredField]}>
                    <Select
                        onSelect={(id) =>
                            setSelectedContract(contracts.filter((x) => x.id === id)[0])
                        }
                        disabled={!selectedCustomer}
                        options={contracts.map((x) => ({ label: x.name, value: x.id }))}
                    />
                </Form.Item>
                {selectedContract?.requireMultiplier && (
                    <Form.Item
                        label={"Coefficiente valorizzazione"}
                        name={"multiplier"}
                        rules={[requiredField]}
                    >
                        <Select
                            disabled={!selectedContract}
                            options={multipliers.map((x) => ({ label: x.name, value: x.id }))}
                        />
                    </Form.Item>
                )}
                <Form.Item label={"Indirizzo"} rules={[requiredField]} name={"address"}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Città"} rules={[requiredField]} name={"city"}>
                    <Select
                        showSearch
                        onSearch={handleSearchCityChange}
                        filterOption={false}
                        notFoundContent={
                            loadingCities ? "Caricamento in corso..." : "Nessun elemento"
                        }
                        options={cities.map((x) => ({ label: x.name, value: x.id }))}
                    />
                </Form.Item>
                <Form.Item style={{ textAlign: "end" }}>
                    <Button htmlType={"submit"} loading={loading} type={"primary"}>
                        Salva
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
