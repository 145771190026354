import {useParams} from "react-router-dom";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import axiosInstance from "../api";
import {Form, Input, Table} from "antd";
import dayjs from "dayjs";
import {NumericFormat} from "react-number-format";
import OrderAccountingReportTable from "./OrderAccountingReportTable";


export default function OrderAccountingReport() {
    const {orderId} = useParams()
    const [report, setReport] = useState({});
    const [loading, setLoading] = useState(false)

    const fetchData = () => {
        setLoading(true)
        axiosInstance.get(`/order/${orderId}/accountingReport`)
            .then(({data}) => setReport(data))
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        fetchData()
    }, []);


    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <OrderAccountingReportTable report={report} refresh={fetchData} />
    </div>
}
