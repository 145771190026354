import {Button, Form, message} from "antd";
import {DownloadOutlined, ImportOutlined, UploadOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import Papa from "papaparse";
import axiosInstance from "../api";
import {exportCsv} from "../util";


export default function ImportListing() {
    const {id} = useParams();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const onFinish = (form) => {
        setLoading(true)
        Papa.parse(form.file.file, {
            skipEmptyLines: true,
            complete: ({data}) => {
                const toSend = data.slice(1).map(x => ({
                    category: Number(x[0]),
                    refId: x[1],
                    description: x[2],
                    measureUnit: x[3],
                    basePrice: x[4],
                    applyMultiplier: Number(x[5]) === 1,
                }))
                axiosInstance.post(`/listing/${id}/element`, toSend)
                    .then(() => {
                        message.success("Listino importato")
                    })
                    .then(() => navigate(-1))
                    .finally(() => setLoading(false))
            },
        });
    }

    return <div>
        <Button onClick={() => exportCsv('Categoria;ID;Descrizione;UnitaMisura;Prezzo/Punti;ApplicaValorizzazione')}>Scarica
            modello</Button>
        <Form onFinish={onFinish}>
            <Form.Item label={"File"} name='file' valuePropName={'file'}>
                <Dragger maxCount={1}
                         beforeUpload={() => false}>
                    <p>Trascina qui il modello modificato</p>
                    <Button icon={<UploadOutlined/>}>Seleziona file</Button>
                </Dragger>
            </Form.Item>
            <Form.Item>
                <Button htmlType={"submit"} type={"primary"} loading={loading}>Importa</Button>
            </Form.Item>
        </Form>
    </div>
}