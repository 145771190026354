import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {ConfigProvider} from "antd";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import itIT from 'antd/locale/it_IT';
import 'dayjs/locale/it'
import CheckLogin from "./CheckLogin";
import Listings from "./listing/Listings";
import NewListing from "./listing/NewListing";
import ListingDetails from "./listing/ListingDetails";
import NewListingElement from "./listing/NewListingElement";
import EditListingElement from "./listing/EditListingElement";
import EditListing from "./listing/EditListing";
import Customers from "./customer/Customers";
import NewCustomer from "./customer/NewCustomer";
import CustomerDetails from "./customer/CustomerDetails";
import NewContract from "./customer/contract/NewContract";
import EditContract from "./customer/contract/EditContract";
import ContractDetails from "./customer/contract/ContractDetails";
import NewMultiplier from "./customer/contract/multiplier/NewMultiplier";
import EditMultiplier from "./customer/contract/multiplier/EditMultiplier";
import ImportDiscounts from "./customer/contract/discount/ImportDiscounts";
import Orders from "./order/Orders";
import NewOrder from "./order/NewOrder";
import OrderDetails from "./order/OrderDetails";
import NewOrderAccounting from "./order/NewOrderAccounting";
import OrderAccountingDetails from "./order/OrderAccountingDetails";
import NewOrderAccountingSection from "./order/NewOrderAccountingSection";
import NewSectionItem from "./order/NewSectionItem";
import EditSection from "./order/EditSection";
import EditSectionItem from "./order/EditSectionItem";
import EditOrder from "./order/EditOrder";
import ImportListing from "./listing/ImportListing";
import Dashboard from "./dashboard/Dashboard";

import dayjs from "dayjs";
import EditOrderAccounting from "./order/EditOrderAccounting";
import AccountingDetails from "./order/AccountingDetails";
import Contacts from "./contact/Contacts";
import NewContact from "./contact/NewContact";
import EditContact from "./contact/EditContact";
import Functions from './function/Functions';
import Customersheet from './customersheet/Customersheet';
import Teams from './team/Teams';
import EditTeam from './team/EditTeam';
import NewTeam from './team/NewTeam';

const router = createBrowserRouter([
    {
        path: "/",
        element: <CheckLogin/>,
        children: [

            {
                path: "listings",
                element: <Listings/>
            },
            {
                path: "listings/new",
                element: <NewListing/>
            },
            {
                path: "listings/:id",
                element: <ListingDetails/>
            },
            {
                path: "listings/:id/edit",
                element: <EditListing/>
            },
            {
                path: "listings/:id/new",
                element: <NewListingElement/>
            },
            {
                path: "listings/:id/import",
                element: <ImportListing/>
            },
            {
                path: "listings/:id/elements/:elementId/edit",
                element: <EditListingElement/>
            },
            {
                path: "customers",
                element: <Customers/>
            },
            {
                path: "customers/new",
                element: <NewCustomer/>
            },
            {
                path: "customers/:id",
                element: <CustomerDetails/>
            },
            {
                path: "customers/:id/contracts/new",
                element: <NewContract/>
            },
            {
                path: "customers/:id/contracts/:contractId/edit",
                element: <EditContract/>
            },
            {
                path: "customers/:id/contracts/:contractId",
                element: <ContractDetails/>
            },
            {
                path: "customers/:id/contracts/:contractId/multipliers/new",
                element: <NewMultiplier/>
            },

            {
                path: "customers/:id/contracts/:contractId/multipliers/:multiplierId/edit",
                element: <EditMultiplier/>
            },
            {
                path: "customers/:id/contracts/:contractId/discounts/import",
                element: <ImportDiscounts/>
            },
            {
                path: "orders",
                element: <Orders/>
            },
            {
                path: "orders/new",
                element: <NewOrder/>
            },
            {
                path: "orders/:orderId",
                element: <OrderDetails/>
            },
            {
                path: "orders/:orderId/edit",
                element: <EditOrder/>
            },
            {
                path: "orders/:orderId/accountings/new",
                element: <NewOrderAccounting/>
            },
            {
                path: "orders/:orderId/accountings/:accountingId/edit",
                element: <EditOrderAccounting/>
            },
            {
                path: "orders/:orderId/accountings/:accountingId",
                element: <AccountingDetails/>
            },
            {
                path: "orders/:orderId/accountings/:accountingId/sections/new",
                element: <NewOrderAccountingSection/>
            },
            {
                path: "orders/:orderId/accountings/:accountingId/sections/:sectionId/edit",
                element: <EditSection/>
            },
            {
                path: `orders/:orderId/accountings/:accountingId/sections/:sectionId/items/new`,
                element: <NewSectionItem/>
            },
            {
                path: `orders/:orderId/accountings/:accountingId/sections/:sectionId/items/:itemId/edit`,
                element: <EditSectionItem/>
            },
            {
                path: 'dashboard',
                element: <Dashboard/>
            },
            {
                path: 'contacts',
                element: <Contacts/>
            },
            {
                path: 'contacts/new',
                element: <NewContact/>
            },
            {
                path: 'contacts/:contactId/edit',
                element: <EditContact/>
            },
            {
                path: 'functions',
                element: <Functions/>
            },
            {
                path: 'customersheets',
                element: <Customersheet/>
            },
            {
                path: 'teams',
                element: <Teams/>
            },
            {
                path: 'teams/new',
                element: <NewTeam/>
            },
            {
                path: 'teams/:teamId/edit',
                element: <EditTeam/>
            }
        ]
    },
])

dayjs.locale('it')
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ConfigProvider locale={itIT} theme={{
        token: {borderRadius: 3},
        components: {
            Button: {primaryShadow: null}
        }
    }}>
        <RouterProvider router={router}/>
    </ConfigProvider>
);

