import OrderAccountings from "./OrderAccountings";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { useNavigate, useParams } from "react-router-dom";
import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Popconfirm,
    Row,
    Space,
    Spin,
    Tabs,
    Tag,
    Tooltip,
} from "antd";
import OrderAccountingReport from "./OrderAccountingReport";
import BackTitle from "../common/BackTitle";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import OrderAccountingEmailHistory from "./OrderAccountingEmailHistory";

export default function OrderDetails() {
    const { orderId } = useParams();
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axiosInstance
            .get(`order/${orderId}`)
            .then(({ data }) => setOrder(data))
            .finally(() => setLoading(false));
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Row>
                <Col span={12}>
                    <BackTitle title={"Dettagli commessa"} padding={0} />
                </Col>
                <Col span={12}>
                    <Space
                        style={{ width: "100%", height: "100%", justifyContent: "end" }}
                        wrap
                        split={<Divider type="vertical" />}
                    >
                        <Button
                            type={"link"}
                            icon={<EditOutlined />}
                            onClick={() => navigate("edit")}
                        >
                            Modifica
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Spin spinning={loading}>
                <Descriptions colon={3} layout={"vertical"} size={"small"}>
                    <Descriptions.Item label={"Nome"} span={3}>
                        {order.title}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Cliente"}>
                        {order.contract?.customer?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Contratto"}>
                        {order.contract?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Coefficiente"}>
                        {order.multiplier?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Indirizzo"}>{order.address}</Descriptions.Item>
                    <Descriptions.Item label={"Città"}>
                        {order.city?.name + ", " + order.city?.region}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Creato da"}>
                        {order.createdBy?.firstName + " " + order.createdBy?.lastName}
                    </Descriptions.Item>
                </Descriptions>
            </Spin>
            <Tabs
                animated={false}
                destroyInactiveTabPane
                items={[
                    {
                        key: 0,
                        label: "Fogli di contabilità",
                        children: <OrderAccountings customerId={order.contract?.customer?.id} />,
                    },
                    {
                        key: 1,
                        label: "Dettaglio lavori",
                        children: <OrderAccountingReport />,
                    },
                    {
                        key: 2,
                        label: "Storico invio email",
                        children: <OrderAccountingEmailHistory />,
                    },
                ]}
            ></Tabs>
        </div>
    );
}
