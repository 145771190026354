
import BackTitle from "../common/BackTitle";
import {Button, Form, Input, message, Select} from "antd";
import {filterOption, requiredField} from "../util";
import {useEffect, useState} from "react";
import axiosInstance from "../api";
import {useNavigate} from "react-router-dom";


export default function NewTeam(){

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    const onFinish = (form) =>{
        setLoading(true)
        axiosInstance.post(`team`,form)
            .then(() => navigate(-1))
            .then(() => {message.success("Squadra creata")})
            .finally(() => setLoading(false))
    }

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <BackTitle title={"Nuova squadra"} padding={0}/>
        <Form layout={"vertical"} onFinish={onFinish}>
            <Form.Item label={"Nome"} name={"name"} rules={[requiredField]}>
               <Input/>
            </Form.Item>
            <Form.Item label={"Descrizione"} name={"description"}>
               <Input/>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button htmlType={"submit"} type={"primary"} loading={loading}>Salva</Button>
            </Form.Item>
        </Form>
    </div>
}
