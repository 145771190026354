import { Link, useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, message, Popover, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import {
    CheckCircleTwoTone,
    CheckOutlined,
    CheckSquareTwoTone,
    CloseCircleTwoTone,
    FileExcelOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    SendOutlined,
} from "@ant-design/icons";
import Toolbar from "../common/Toolbar";
import { downloadFile, emptyOrPlaceholder, formatDateTime } from "../util";
import SelectEmailRecipients from "./SelectEmailRecipients";
import dayjs from "dayjs";
import NewOrderAccounting from "./NewOrderAccounting";

const columns = [
    {
        key: "id",
        dataIndex: "id",
        width: 100,
        title: "Foglio n.",
    },
    {
        key: "name",
        dataIndex: "name",
        title: "Nome",
        render: (x, record) => <Link to={`accountings/${record.id}`}>{emptyOrPlaceholder(x)}</Link>,
    },
    {
        key: "month",
        dataIndex: "month",
        title: "Competenza",
        render: (x) => dayjs(x).format("MMMM YYYY"),
    },
    {
        key: "assignedTo",
        dataIndex: "assignedTo",
        title: "Assegnatario",
        render: (x) => x ? (
             <Tooltip title={x.fullName} placement="top">
                <Avatar
                    size={"small"}
                    style={{
                        backgroundColor: "orange",
                    }}
                >
                    {x.fullName
                        .split(" ")
                        .map((z) => z[0].toUpperCase())
                        .join("")}
                </Avatar>
            </Tooltip>
        ) : '',
    },
    {
        key: "emailSent",
        dataIndex: "emailSent",
        title: "Inviato",
        render: (x) =>
            x ? (
                <CheckCircleTwoTone twoToneColor="green" />
            ) : (
                <CloseCircleTwoTone twoToneColor="red" />
            ),
    },
    {
        key: "createdBy",
        dataIndex: "createdBy",
        title: "Creato da",
        render: (x) => x.firstName + " " + x.lastName,
    },

    {
        key: "creationDate",
        dataIndex: "creationTs",
        title: "Data creazione",
        render: formatDateTime,
    },
    {
        key: "updatedBy",
        dataIndex: "updatedBy",
        title: "Modificato da",
        render: (x) => x.firstName + " " + x.lastName,
    },

    {
        key: "updateDate",
        dataIndex: "updateTs",
        title: "Data modifica",
        render: formatDateTime,
    },
];

export default function OrderAccountings({ customerId }) {
    const { orderId } = useParams();
    const [accountings, setAccountings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [sendMailLoading, setSendMailLoading] = useState(false);
    const navigate = useNavigate();
    const [newAccountingOpen, setNewAccountingOpen] = useState(false);

    const fetchData = () => {
        setLoading(true);
        axiosInstance
            .get(`accounting`,{params: {orderId: orderId, pageSize: 99999, page: 0}})
            .then(({ data }) => setAccountings(data.data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        fetchData();
    }, []);

    const deleteAccountings = (keys) => {
        setLoading(true);
        axiosInstance
            .delete(`/accounting?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(() => setSelectedRowKeys([]))
            .then(() => fetchData())
            .finally(() => setLoading(false));
    };

    const downloadExcelReport = (keys) =>
        downloadFile(
            `/order/${orderId}/accountingReport/export?${keys.map((x) => `ids=${x}`).join("&")}`,
        );

    const flagAsSent = (keys) => {
        setLoading(true);
        axiosInstance
            .put(`/accounting/flagAsSent?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(() => {
                message.success("Elementi aggiornati");
                const newData = accountings.map((x) =>
                    keys.includes(x.id) ? { ...x, emailSent: true } : x,
                );
                setAccountings(newData);
            })
            .finally(() => setLoading(false));
    };

    const popoverContent = (
        <SelectEmailRecipients
            customerId={customerId}
            onFinish={(data) => {
                setSendMailLoading(true);
                axiosInstance
                    .post(`order/${orderId}/accountingReport/mail`, {
                        ...data,
                        accountingIds: selectedRowKeys,
                    })
                    .then(() => {
                        message.success("Mail inviata");
                        const newData = accountings.map((x) =>
                            selectedRowKeys.includes(x.id) ? { ...x, emailSent: true } : x,
                        );
                        setAccountings(newData);
                    })
                    .then(() => setSelectedRowKeys([]))
                    .finally(() => {
                        setSendMailLoading(false);
                        setPopoverOpen(false);
                    });
            }}
            onCancel={() => setPopoverOpen(false)}
        />
    );
    return (
        <div>
            <Toolbar
                selectedKeys={selectedRowKeys}
                addButton={
                    <Popover
                        trigger={"click"}
                        open={newAccountingOpen}
                        onOpenChange={setNewAccountingOpen}
                        destroyTooltipOnHide
                        placement={"right"}
                        content={
                            <NewOrderAccounting
                                onOk={() => {
                                    fetchData();
                                    setNewAccountingOpen(false);
                                }}
                            />
                        }
                    >
                        <Button type={"primary"} icon={<PlusOutlined />}>
                            Nuovo foglio
                        </Button>
                    </Popover>
                }
                addAction={() => navigate("accountings/new")}
                editAction={(key) => navigate(`accountings/${key}/edit`)}
                deleteAction={deleteAccountings}
                extra={(keys) => (
                    <>
                        <Button
                            disabled={keys.length === 0}
                            type={"text"}
                            icon={<FileExcelOutlined />}
                            onClick={() => downloadExcelReport(keys)}
                        >
                            Esporta
                        </Button>
                        <Popover
                            trigger={"click"}
                            open={popoverOpen}
                            onOpenChange={setPopoverOpen}
                            destroyTooltipOnHide
                            content={popoverContent}
                        >
                            <Button
                                loading={sendMailLoading}
                                onClick={() => setPopoverOpen(true)}
                                disabled={keys.length === 0}
                                type={"text"}
                                icon={<SendOutlined />}
                            >
                                Invia email
                            </Button>
                        </Popover>
                        <Button
                            disabled={keys.length === 0}
                            type={"text"}
                            icon={<CheckOutlined />}
                            onClick={() => flagAsSent(keys)}
                        >
                            Segna come inviato
                        </Button>
                    </>
                )}
            />
            <Table
                size={"small"}
                loading={loading}
                columns={columns}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            setSelectedRowKeys([record.key]);
                        }, // click row
                    };
                }}
                rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys }}
                dataSource={accountings
                    .map((x) => ({ ...x, key: x.id }))
                    .sort((a, b) => a.id - b.id)}
            />
        </div>
    );
}
