import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Input, InputNumber, message} from "antd";
import {requiredField} from "../../../util";
import {useState} from "react";
import axiosInstance from "../../../api";
import BackTitle from "../../../common/BackTitle";


export default function NewMultiplier() {
    const {id, contractId} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onFinish = (form) => {
        setLoading(true)
        axiosInstance.post(`customer/${id}/contract/${contractId}/multiplier`, form)
            .then(() => {
                message.success("Coefficiente creato")
            })
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <BackTitle padding={0} title={"Nuovo coefficiente"}/>
        <Form onFinish={onFinish} layout={"vertical"}>
            <Form.Item label={"Nome"} name={"name"} rules={[requiredField]}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Valore"} name={"multiplier"} rules={[requiredField]}>
                <InputNumber min={0}/>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button htmlType={"submit"} loading={loading} type={"primary"}>Salva</Button>
            </Form.Item>
        </Form>
    </div>
}