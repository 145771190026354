import {useParams} from "react-router-dom";
import {Descriptions, Spin, Tabs} from "antd";
import ContractMultipliers from "./multiplier/ContractMultipliers";
import Discounts from "./Discounts";
import BackTitle from "../../common/BackTitle";
import {useEffect, useState} from "react";
import axiosInstance from "../../api";


export default function ContractDetails() {
    const {customerId, contractId} = useParams()
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState({})

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`customer/${customerId}/contract/${contractId}`)
            .then(({data}) => setContract(data))
            .finally(() => setLoading(false))
    }, []);

    return <Spin spinning={loading}>
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle padding={0} title={`Dettaglio contratto: ${contract.name}`}/>
            <Descriptions layout={"vertical"}>
                <Descriptions.Item label={"Listino"}>{contract.listing?.name}</Descriptions.Item>
            </Descriptions>
            <Tabs
                items={[
                    {
                        key: 1,
                        label: 'Coefficienti',
                        children: <ContractMultipliers/>
                    },
                    {
                        key: 2,
                        label: 'Sconti',
                        children: <Discounts/>
                    }
                ]}
            />
        </div>
    </Spin>
}