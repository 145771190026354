import {Button, Form, message} from "antd";
import {DownloadOutlined, ImportOutlined, UploadOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../api";
import {useState} from "react";
import Papa from "papaparse";
import {exportCsv} from "../../../util";
import BackTitle from "../../../common/BackTitle";

export default function ImportDiscounts() {

    const {contractId} = useParams();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const downloadTemplate = () => {
        axiosInstance.get(`/contract/${contractId}/discount/template`)
            .then(({data}) => {
                const header = 'ID;Categoria;Voce;Descrizione;Sconto percentuale\n';
                const content = data
                    .sort((a, b) => a.category.localeCompare(b.category))
                    .map(x => `${x.id};${x.category};${x.refId};${x.description};${(x.discount ?? 0) * 100}`)
                    .join('\n')
                exportCsv(header + content,`sconti_${contractId}`)
            })
    }

    const onFinish = (form) => {
        setLoading(true)
        Papa.parse(form.file.file, {
            skipEmptyLines: true,
            complete: ({data}) => {
                const toSend = data.slice(1).map(x => ({listingElement: x[0], discount: Number(x[4]) / 100}))
                axiosInstance.post(`/contract/${contractId}/discount`, toSend)
                    .then(() => {
                        message.success("Sconti importati")
                    })
                    .then(() => navigate(-1))
                    .finally(() => setLoading(false))
            },
        });
    }

    return <div>
        <BackTitle title={"Importa sconti"} padding={0}/>
        <p>Questa procedura permette di modificare o importare gli sconti in modo massivo. Scaricare il modello, modificare <b>soltanto</b> il campo sconto percentuale (0-100) e caricare il modello salvato.</p>
        <div style={{marginTop: 30}}>Modello: <Button icon={<DownloadOutlined/>} onClick={downloadTemplate}>Scarica modello</Button></div>
        <Form onFinish={onFinish} style={{marginTop: 20}}>
            <Form.Item label={"File"} name='file' valuePropName={'file'}>
                <Dragger maxCount={1}
                         beforeUpload={() => false}>
                    <p>Trascina qui il modello modificato</p>
                    <Button icon={<UploadOutlined/>}>Seleziona file</Button>
                </Dragger>
            </Form.Item>
            <Form.Item style={{width: '100%',textAlign: "end"}}>
                <Button htmlType={"submit"} type={"primary"} loading={loading}>Importa</Button>
            </Form.Item>
        </Form>
    </div>
}