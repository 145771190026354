import { AutoComplete, Button, Checkbox, Form, Input, InputNumber, message, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import axiosInstance from "../api";
import { useParams } from "react-router-dom";
import { filterOption, requiredField } from "../util";
import { useForm } from "antd/lib/form/Form";

export default function NewSectionItem({ onOk }) {
    const { orderId, accountingId } = useParams();
    const [listingElements, setListingElements] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isExtra, setIsExtra] = useState(false);
    const [form] = useForm();
    const ref = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        axiosInstance
            .get(`order/${orderId}/listing/element`)
            .then(({ data }) => setListingElements(data));
        axiosInstance
            .get(`accountingItem/sectionSuggestion`, {
                params: { accountingId: Number(accountingId) },
            })
            .then(({ data }) => {
                setSuggestions(data);
                setOptions(data.map((x) => ({ value: x })));
            });
        axiosInstance
            .get("team")
            .then(({ data }) => setTeams(data.map((x) => ({ label: x.name, value: x.id }))));
        if (ref) {
            ref.current.focus();
        }
    }, []);

    const onFinish = (form) => {
        setLoading(true);
        axiosInstance
            .post(`accountingItem`, { ...form, accountingId: accountingId })
            .then(() => {
                message.success("Elemento salvato");
            })
            .then(() => onOk?.())
            .finally(() => setLoading(false));
    };

    return (
        <Form
            onFinish={onFinish}
            className={"popover-form"}
            form={form}
            layout={"vertical"}
            style={{ width: 500 }}
        >
            <Form.Item name={"isExtra"} valuePropName={"checked"} label={"Tipo"}>
                <Checkbox checked={isExtra} onChange={({ target }) => setIsExtra(target.checked)}>
                    Voce extra listino
                </Checkbox>
            </Form.Item>
            {!isExtra && (
                <Form.Item label={"Voce"} name={"listingElement"} rules={[requiredField]}>
                    <Select
                        ref={ref}
                        showSearch
                        filterOption={filterOption}
                        options={listingElements.map((x) => ({
                            label: x.refId + " - " + x.description,
                            value: x.id,
                        }))}
                    />
                </Form.Item>
            )}
            {isExtra && (
                <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
            )}
            <Form.Item label={"Tratta (Opzionale)"} name={"section"}>
                <AutoComplete
                    options={options}
                    onSearch={(x) =>
                        setOptions(
                            suggestions
                                .filter((y) => y.toLowerCase().includes(x.toLowerCase()))
                                .map((x) => ({ value: x })),
                        )
                    }
                />
            </Form.Item>
            <Form.Item label={"Quantità"} name={"quantity"} rules={[requiredField]}>
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label={"Note"} name={"notes"}>
                <Input />
            </Form.Item>

            <Form.Item label={"Squadra"} name={"teamId"}  rules={[requiredField]}>
                <Select allowClear showSearch filterOption={filterOption} options={teams}/>
            </Form.Item>

            <Form.Item style={{ textAlign: "end", marginBottom: 0 }}>
                <Button htmlType={"submit"} loading={loading} type={"primary"}>
                    Salva
                </Button>
            </Form.Item>
        </Form>
    );
}
