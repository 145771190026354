import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../api";
import {useForm} from "antd/lib/form/Form";
import {Button, Form, Input, message, Spin} from "antd";
import {requiredField} from "../util";
import BackTitle from "../common/BackTitle";

export default function (){
    const {id} = useParams();
    const navigate = useNavigate();
    const [initialLoading, setInitialLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = useForm();

    useEffect(() => {
        setInitialLoading(true)
        axiosInstance.get(`listing/${id}`)
            .then(({data}) => form.setFieldsValue(data))
            .finally(() => setInitialLoading(false))
    }, []);

    const onFinish = form => {
        setLoading(true)
        axiosInstance.put(`listing/${id}`,form)
            .then(() => {message.success("Listino modificato con successo")})
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }
    return <Spin spinning={initialLoading}>
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle padding={0} title={`Modifica listino`}/>
            <Form form={form} onFinish={onFinish} layout={"vertical"}>
                <Form.Item label={"Nome"} name={"name"} rules={[requiredField]}>
                    <Input/>
                </Form.Item>
                <Form.Item style={{textAlign: "end"}}>
                    <Button htmlType={"submit"} loading={loading} type={"primary"}>Salva</Button>
                </Form.Item>
            </Form>
        </div>
    </Spin>

}
