import BackTitle from "../common/BackTitle";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { filterOption, requiredField } from "../util";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";

export default function EditTeam() {
    const { teamId } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = useForm();
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        setPageLoading(true);
        axiosInstance
            .get(`team/${teamId}`)
            .then(({ data }) => form.setFieldsValue(data))
            .finally(() => setPageLoading(false));
    }, []);

    const onFinish = (form) => {
        setLoading(true);
        axiosInstance
            .put(`team/${teamId}`, form)
            .then(() => navigate(-1))
            .then(() => {
                message.success("Squadra aggiornata");
            })
            .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={pageLoading}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <BackTitle title={"Modifica contatto"} padding={0} />
                <Form layout={"vertical"} onFinish={onFinish} form={form}>
                    <Form.Item label={"Nome completo"} name={"name"} rules={[requiredField]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "end" }}>
                        <Button htmlType={"submit"} type={"primary"} loading={loading}>
                            Salva
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    );
}
