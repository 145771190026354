import {Button, Divider, Popconfirm, Space} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";


export default function Toolbar({selectedKeys, addButton, addAction, editAction, deleteAction, extra, style, topDivider}) {
    selectedKeys = selectedKeys ?? []
    topDivider = topDivider !== undefined ? topDivider : true
    addButton = addButton ?? <Button icon={<PlusOutlined/>} type={"primary"} onClick={addAction}>Aggiungi</Button>
    return <div {...{style}}>
        {topDivider && <Divider style={{margin: 0, marginBottom: 8}}/>}
        <Space wrap split={<Divider type="vertical" />} >
            {addAction && addButton}
            {editAction && <Button type={"text"} disabled={selectedKeys.length !== 1} icon={<EditOutlined/>}
                    onClick={() => editAction(selectedKeys[0])}>Modifica</Button>}
            {deleteAction && <Popconfirm
                title={`Elimina ${selectedKeys.length} elementi`}
                description={`Verranno eliminati ${selectedKeys.length} elementi. Procedere?`}
                okText="Si"
                cancelText="No"
                onConfirm={() => deleteAction(selectedKeys)}
            >
                <Button danger type={"text"} disabled={selectedKeys.length === 0}
                        icon={<DeleteOutlined/>}>Elimina</Button>
            </Popconfirm>}
            {extra?.(selectedKeys)}
        </Space>
        <Divider style={{margin: 0, marginTop: 8}}/>
    </div>
}