import { UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Modal } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../api";
import { requiredField } from "../util";

export default function ImportOrderAccounting({ open, setOpen, onSuccess}) {
    const { orderId, accountingId } = useParams();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [formInstance] = useForm()

    const onFormFinish = (form) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", form.file.file);
        axiosInstance
            .post(`/accounting/${accountingId}/import`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(({ data }) => {
                if(!data.errorMessage){
                    onSuccess()
                    setOpen(false)
                }
                formInstance.resetFields()
                setErrorMessage(data.errorMessage);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!open) setErrorMessage();
    }, [open]);

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            okButtonProps={{ form: "importAccountingForm", htmlType: "submit", loading: loading }}
            destroyOnClose={true}
            title={"Carica modello Excel"}
            okText={"Carica"}
        >
            <div>
                {errorMessage && (
                    <Alert message="Errore" description={errorMessage} type="error" showIcon />
                )}
                <p>
                    Il file excel ad importare è <b>foglio_contabilita_{accountingId}.xlsx</b>
                </p>
                <Form
                    id={"importAccountingForm"}
                    onFinish={onFormFinish}
                    form={formInstance}
                    layout={"vertical"}
                    style={{ marginTop: 16 }}
                >
                    <Form.Item label={"File"} name="file" valuePropName={"file"} rules={[requiredField]}>
                        <Dragger maxCount={1} beforeUpload={() => false}>
                            <p>Trascina qui il file oppure clicca per selezionarlo</p>
                        </Dragger>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}
