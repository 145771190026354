import {useEffect, useState} from "react";
import axiosInstance from "../api";
import {Button, Col, Row, Space, Table} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {formatDateTime} from "../util";
import BackTitle from "../common/BackTitle";
import {PlusOutlined} from "@ant-design/icons";


const columns = [
    {
        title: "Nome",
        dataIndex: "name",
        key: "name",
        width: 150,
        ellipsis: true,
        render: (name, record) => <Link to={`${record.id}`}>{name}</Link>
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: 'Creato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'creationDate',
        dataIndex: 'creationDate',
        title: 'Data creazione',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },
    {
        key: 'updatedBy',
        dataIndex: 'updatedBy',
        title: 'Modificato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'updateDate',
        dataIndex: 'updateDate',
        title: 'Data modifica',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },
];
export default function Listings() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("listing")
            .then(({data}) => setData(data))
            .finally(() => setLoading(false));
    }, []);

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <Row>
            <Col span={12}>
                <BackTitle backEnabled={false} padding={0} title={"Listini"}/>
            </Col>
            <Col span={12}>
                <Space style={{width: '100%', height: '100%', alignItems: "center", justifyContent: "end"}}>
                   <Button icon={<PlusOutlined/>} type={"primary"} onClick={() => navigate('new')}>Nuovo</Button>
                </Space>
            </Col>
        </Row>
        <Table
            style={{marginTop: 20}}
            size={"small"}
            columns={columns}
            scroll={{x: columns.reduce((acc,x)=>acc+x,0)}}
            dataSource={data.map(x => ({...x, key: x.id}))}
            tableLayout={"fixed"}
            loading={loading}/>
    </div>
}