import axiosInstance from "./api";
import dayjs from "dayjs";
import {keycloakInstance} from "./keycloak";
import {Tooltip} from "antd";

export const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const emptyOrPlaceholder = txt => !txt ? "<Senza nome>" : txt
export const formatDateTime = (dt) => dayjs(dt).format("DD/MM/YYYY HH:mm")


export const exportCsv = (csvContent,title) => {
    const csvMeta = "data:text/csv;charset=utf-8," + "\ufeff";
    const csv = csvMeta + csvContent;
    let encodedUri = encodeURI(csv);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${title}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
    document.body.removeChild(link);
}


export const downloadFile = (path, method, body, config) => {
    const fun = {
        "get": () => axiosInstance.get(path, { ...(config ?? {}), responseType: "blob"}),
        "post": () => axiosInstance.post(path, body, {...(config ?? {}), responseType: "blob"})
    }[method ?? "get"];
    return fun()
        .then(response => {
            const href = URL.createObjectURL(response.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', response.headers.get("Content-Disposition").split("filename=")[1]); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
}


export const textFromHtml = (html) => {
    return new DOMParser()
        .parseFromString(html, "text/html")
        .documentElement.textContent;
}


export const  renderDaysLeft = (d) => {
    const dayjsDate = dayjs(d,"YYYY-MM-DD");
    const left = dayjsDate.diff(dayjs().startOf('day'),'days')
    const colors = [{days: 14, color: 'red'}, {days: 30, color: '#ffa000'}, {days:60, color: 'green'}]
    const selectedColor = colors.filter(x => left <= x.days)[0]?.color ?? 'black';
    return <span style={{color : selectedColor}}>{dayjsDate.format("DD/MM/YYYY")} ({left} giorni)</span>
}

export const requiredField = {
    required: true,
    message: 'Campo obbligatorio'
}

export const hasAnyRole = (roles) => roles.map(keycloakInstance.hasRealmRole).reduce((a,b) => a || b);


export const withTooltip = (content) => <Tooltip placement={"topLeft"} title={content}>{content}</Tooltip>
