import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Input, message, Radio, Select} from "antd";
import {filterOption, requiredField} from "../../util";
import axiosInstance from "../../api";
import BackTitle from "../../common/BackTitle";


export default function NewContract() {
    const [listings, setListings] = useState([])
    const [loading, setLoading] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get('listing')
            .then(({data}) => setListings(data))
    }, []);

    const onFinish = form => {
        setLoading(true)
        axiosInstance.post(`customer/${id}/contract`, {...form, customer: id})
            .then(() => {
                message.success("Contratto creato")
            })
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <BackTitle padding={0} title={"Nuovo contratto"}/>
        <Form onFinish={onFinish} layout={"vertical"}>
            <Form.Item label={"Nome"} name={"name"} rules={[requiredField]}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Listino"} name={"listing"} rules={[requiredField]}>
                <Select showSearch filterOption={filterOption} options={listings.map(x => ({label: x.name, value: x.id}))}/>
            </Form.Item>
            <Form.Item label={"Richiedi coefficiente"} name={"requireMultiplier"} rules={[requiredField]}>
                <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>no</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button htmlType={"submit"} loading={loading} type={"primary"}>Salva</Button>
            </Form.Item>
        </Form>
    </div>
}