import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { Button, DatePicker, Form, Input, message, Select, Spin } from "antd";
import BackTitle from "../common/BackTitle";
import { filterOption, requiredField } from "../util";
import dayjs from "dayjs";
import { useForm } from "antd/lib/form/Form";

const { MonthPicker } = DatePicker;

export default function EditOrderAccounting() {
    const { accountingId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [form] = useForm();
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        axiosInstance
            .get(`/accounting/${accountingId}`)
            .then(({ data }) =>
                form.setFieldsValue({
                    ...data,
                    month: dayjs(data.month),
                    assignedTo: data.assignedTo.id,
                }),
            )
            .finally(() => setPageLoading(false));
        axiosInstance.get("user").then(({ data }) => setUsers(data));
    }, []);

    const onFinish = (form) => {
        form.month = form.month.format("YYYY-MM-DD");
        setLoading(true);
        axiosInstance
            .put(`/accounting/${accountingId}`, form)
            .then(() => navigate(-1))
            .then(() => {
                message.success("Foglio di contabilità modificato");
            })
            .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={pageLoading}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <BackTitle padding={0} title={"Modifica foglio di contabilità"} />
                <Form onFinish={onFinish} layout={"vertical"} form={form}>
                    <Form.Item label={"Nome"} name={"name"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={"Mese di competenza"} name={"month"} rules={[requiredField]}>
                        <MonthPicker format={"MMMM YYYY"} />
                    </Form.Item>
                    <Form.Item label={"Assegnatario"} name={"assignedTo"} rules={[requiredField]}>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            options={users.map((x) => ({ label: x.fullName, value: x.id }))}
                        />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "end" }}>
                        <Button htmlType={"submit"} type={"primary"} loading={loading}>
                            Salva
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    );
}
