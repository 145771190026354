import {useEffect, useState} from "react";
import {Spin} from "antd";
import App from "./App";
import {keycloack} from "./keycloak";
import {useLocation, useNavigate} from "react-router-dom";


export default function CheckLogin() {
    const [authenticated, setAuthenticated] = useState(false);
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        keycloack()
            .then(() => setAuthenticated(true))
            .then(() => {
                if(location.pathname === '/') {
                    navigate('/orders', {replace: true})
                }
            })
    }, []);

    return authenticated ? <App/> : <div style={{width: '100%', height: '100vh', display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Spin size={"large"} spinning={true}/>
    </div>
}