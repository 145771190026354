import BackTitle from "../common/BackTitle";
import {Button, Form, Input, message, Select, Spin} from "antd";
import {filterOption, requiredField} from "../util";
import {useEffect, useState} from "react";
import axiosInstance from "../api";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "antd/lib/form/Form";


export default function EditContact() {

    const {contactId} = useParams();
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [form] = useForm();
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        setPageLoading(true)
        axiosInstance.get('customer')
            .then(({data}) => setCustomers(data))
        axiosInstance.get(`contact/${contactId}`)
            .then(({data}) => form.setFieldsValue({...data, customer: data.customer.id}))
            .finally(() => setPageLoading(false))
    }, []);

    const onFinish = (form) => {
        setLoading(true)
        axiosInstance.put(`contact/${contactId}`, form)
            .then(() => navigate(-1))
            .then(() => {
                message.success("Contatto aggiornato")
            })
            .finally(() => setLoading(false))
    }

    return <Spin spinning={pageLoading}>
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle title={"Modifica contatto"} padding={0}/>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>
                <Form.Item label={"Nome completo"} name={"fullName"} rules={[requiredField]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Cliente"} name={"customer"} rules={[requiredField]}>
                    <Select showSearch filterOption={filterOption}
                            options={customers.map(x => ({label: x.name, value: x.id}))}/>
                </Form.Item>
                <Form.Item label={"Email"} name={"email"}
                           rules={[{type: "email", required: true, message: 'Email non valida'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item style={{textAlign: "end"}}>
                    <Button htmlType={"submit"} type={"primary"} loading={loading}>Salva</Button>
                </Form.Item>
            </Form>
        </div>
    </Spin>
}
