import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Input, InputNumber, message, Spin} from "antd";
import {requiredField} from "../../../util";
import {useEffect, useState} from "react";
import axiosInstance from "../../../api";
import {useForm} from "antd/lib/form/Form";
import BackTitle from "../../../common/BackTitle";


export default function EditMultiplier() {
    const {id, contractId, multiplierId} = useParams();
    const navigate = useNavigate();
    const [initLoading, setInitLoading] = useState(false)
    const [loading, setLoading] = useState(false);
    const [form] = useForm()

    useEffect(() => {
        setInitLoading(true)
        axiosInstance.get(`customer/${id}/contract/${contractId}/multiplier/${multiplierId}`)
            .then(({data}) => form.setFieldsValue(data))
            .finally(() => setInitLoading(false))
    }, []);

    const onFinish = (form) => {
        setLoading(true)
        axiosInstance.put(`customer/${id}/contract/${contractId}/multiplier/${multiplierId}`, form)
            .then(() => {
                message.success("Coefficiente modificato")
            })
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }

    return <Spin spinning={initLoading}>
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle padding={0} title={"Modifica coefficiente"}/>
            <Form onFinish={onFinish} form={form} layout={"vertical"}>
                <Form.Item label={"Nome"} name={"name"} rules={[requiredField]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Valore"} name={"multiplier"} rules={[requiredField]}>
                    <InputNumber min={0}/>
                </Form.Item>
                <Form.Item style={{textAlign: "end"}}>
                    <Button htmlType={"submit"} loading={loading} type={"primary"}>Salva</Button>
                </Form.Item>
            </Form>
        </div>
    </Spin>
}