import {useEffect, useState} from "react";
import axiosInstance from "./../api";
import {Column} from "@ant-design/plots";
import dayjs from "dayjs";
import {Button, Col, DatePicker, Divider, Form, Row, Select, Space, Spin} from "antd";
import {useSearchParams} from "react-router-dom";
import {useForm} from "antd/lib/form/Form";
import TotalChart from "./TotalChart";
import TimeChart from "./TimeChart";
import BackTitle from "../common/BackTitle";
import {filterOption} from "../util";

const {RangePicker} = DatePicker

const selectOptions = [
    {
        value: 'CUSTOMER',
        label: 'Cliente'
    },
    {
        value: 'USER',
        label: 'Utente'
    },
    {
        value: 'CATEGORY',
        label: 'Categoria'
    },
    {
        value: 'TEAM',
        label: 'Squadra'
    }
]

export default function Dashboard() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = useForm();
    const today = dayjs().endOf('month')
    const initialRange = [today.subtract(12, 'month'), today]
    const [customers, setCustomers] = useState([])


    useEffect(() => {
        axiosInstance.get('customer')
            .then(({data}) => setCustomers(data.map(x => ({value: x.id, label: x.name}))))
    }, []);

    useEffect(() => {
        form.resetFields()
        const f = [...searchParams.entries()].reduce((acc, [k, v]) => ({...acc, [k]: v}), {})
        form.setFieldsValue({
            ...f,
            range: f.startDate ? [dayjs(f.startDate), dayjs(f.endDate)] : initialRange,
            customerId: f.customerId ? Number(f.customerId) : undefined
        })
    }, [searchParams]);

    console.log(customers)

    const onFinish = (form) => {
        [...searchParams.keys()].forEach(x => searchParams.delete(x))
        form.startDate = form.range[0].format("YYYY-MM-DD")
        form.endDate = form.range[1].format("YYYY-MM-DD")
        delete form.range
        Object.entries(form)
            .filter(([k, v]) => !!v)
            .forEach(([k, v]) => searchParams.set(k, v))
        setSearchParams(searchParams)
    }

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <BackTitle backEnabled={false} padding={0} title={"Dashboard"}/>
        <Form form={form} onFinish={onFinish} layout={"vertical"} style={{marginTop: 10}}>
            <Row>
                <Col flex={"700px"}>
                    <Space wrap size={20}>
                        <Form.Item style={{marginBottom: 0}} name={"customerId"} label={"Cliente"}>
                            <Select style={{width: 150}} showSearch filterOption={filterOption} allowClear
                                    options={customers}/>
                        </Form.Item>
                        <Form.Item style={{marginBottom: 0}} name={"series"} label={"Raggruppa per"}>
                            <Select style={{width: 150}} allowClear options={selectOptions}/>
                        </Form.Item>
                        <Form.Item style={{marginBottom: 0}} name={"range"} label={"Periodo"}>
                            <RangePicker style={{width: 300}} format={"MMMM YYYY"} allowClear={false}/>
                        </Form.Item>
                    </Space>
                </Col>
                <Col flex={"auto"}>
                    <Space wrap style={{width: '100%', justifyContent: "end", alignContent: "end", height: '100%'}}>
                        <Button onClick={() => {
                            [...searchParams.keys()].forEach(k => searchParams.delete(k))
                            setSearchParams(searchParams)
                        }}>Reset filtri</Button>
                        <Button htmlType={"submit"} type={"primary"}>Applica filtri</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        <Divider orientation={"left"}>Rendiconto mensile</Divider>
        <TimeChart/>
        <Divider orientation={"left"}>Totale del periodo</Divider>
        <TotalChart/>
    </div>
}
