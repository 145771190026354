import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import { filterOption, requiredField } from "../util";
import axiosInstance from "../api";
const { MonthPicker } = DatePicker;

export default function NewOrderAccounting() {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const onFinish = (form) => {
        form.month = form.month.format("YYYY-MM-DD");
        setLoading(true);
        axiosInstance
            .post(`/accounting`, {...form, orderId: orderId})
            .then(({ data }) => navigate(`/orders/${orderId}/accountings/${data.id}`))
            .then(() => {
                message.success("Foglio di contabilità creato");
            })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        axiosInstance.get("user").then(({ data }) => setUsers(data));
    }, []);

    return (
        <Form onFinish={onFinish} className={"popover-form"} layout={"vertical"}>
            <Form.Item label={"Nome"} name={"name"}>
                <Input placeholder="Scavo, Opere civili, Giunzione, Posa" />
            </Form.Item>
            <Form.Item label={"Mese di competenza"} name={"month"} rules={[requiredField]}>
                <MonthPicker format={"MMMM YYYY"} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label={"Assegnatario"} name={"assignedTo"} rules={[requiredField]}>
                <Select
                    showSearch
                    filterOption={filterOption}
                    options={users.map((x) => ({ label: x.fullName, value: x.id }))}
                />
            </Form.Item>
            <Form.Item style={{ textAlign: "end", marginBottom: 0 }} wrapperCol={{ span: 24 }}>
                <Button htmlType={"submit"} type={"primary"} loading={loading}>
                    Salva
                </Button>
            </Form.Item>
        </Form>
    );
}
