import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Modal, Radio, Select} from 'antd';
import axiosInstance from "../api";
import {requiredField} from "../util";
import {useNavigate, useParams} from "react-router-dom";
import BackTitle from "../common/BackTitle";


export default function NewListingElement() {
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate()
    useEffect(() => {
        axiosInstance.get("listingElementCategory")
            .then(({data}) => setCategories(data))
    }, []);

    const onFinish = form => {
        setLoading(true)
        axiosInstance.post(`listing/${id}/element`, [form])
            .then(() => {
                message.success("Elemento aggiunto con successo")
            })
            .finally(() => {
                setLoading(false)
                navigate(-1)
            });
    }


    return (
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle padding={0} title={"Nuovo elemento listino"}/>
            <Form layout={"vertical"} onFinish={onFinish}>
                <Form.Item label={"Categoria"} name={"category"} rules={[requiredField]}>
                    <Select options={categories.map(x => ({label: x.name, value: x.id}))}/>
                </Form.Item>
                <Form.Item label={"Voce"} name={"refId"} rules={[requiredField]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Unità di misura"} name={"measureUnit"} rules={[requiredField]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Prezzo base/Punti"} name={"basePrice"} rules={[requiredField]}>
                    <InputNumber min={0}/>
                </Form.Item>
                <Form.Item label={"Coefficiente"} name={"applyMultiplier"} rules={[requiredField]}>
                    <Radio.Group>
                        <Radio value={true}>Applica</Radio>
                        <Radio value={false}>Non applicare</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item style={{textAlign: "end"}}>
                    <Button type={"primary"} htmlType={"submit"} loading={loading}>Salva</Button>
                </Form.Item>
            </Form>
        </div>
    );
}