import {Button, Form, Input, message} from "antd";
import {requiredField} from "../util";
import axiosInstance from "../api";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import BackTitle from "../common/BackTitle";


export default function NewCustomer(){
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const onFinish = form => {
        setLoading(true)
        axiosInstance.post('customer',form)
            .then(() => {message.success("Cliente creato")})
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }
    return <div style={{display: "flex",flexDirection: "column",rowGap: 16}}>
        <BackTitle padding={0} title={"Nuovo cliente"}/>
        <Form onFinish={onFinish} layout={"vertical"}>
            <Form.Item label={"Nome/Ragione Sociale"} name={"name"} rules={[requiredField]}>
                <Input/>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button htmlType={"submit"} loading={loading} type={"primary"}>Salva</Button>
            </Form.Item>
        </Form>
    </div>
}
