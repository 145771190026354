import {measureTextWidth, Pie} from "@ant-design/plots";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import axiosInstance from "../api";
import {useSearchParams} from "react-router-dom";
import {Spin} from "antd";

export default function TotalChart() {
    function renderStatistic(containerWidth, text, style) {
        const {width: textWidth, height: textHeight} = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const [data, setData] = useState([])
    const [searchParams] = useSearchParams();
    const today = dayjs().endOf('month')
    const initialRange = [today.subtract(12, 'month'), today]
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        let entries = [...searchParams.entries()]
        if (!searchParams.get("startDate"))
            entries = [
                ...entries,
                ['startDate', initialRange[0].format("YYYY-MM-DD")],
                ['endDate', initialRange[1].format("YYYY-MM-DD")]
            ]

        let params = entries.map(([k, v]) => `${k}=${v}`).join("&")
        axiosInstance.get(`stats/total?${params}`)
            .then(({data}) => setData(data))
            .finally(() => setLoading(false))
    }, [searchParams]);

    const config = {
        animation: false,
        appendPadding: 10,
        data: data.sort((a,b) => b.y - a.y).map(x => ({...x, x: x.x ?? 'Totale'})),
        colorField: 'x',
        angleField: 'y',
        radius: 1,
        innerRadius: 0.64,
        label: false,
        legend: {
            layout: 'horizontal',
            position: 'top'
        },
        /*label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            formatter: record => `€ ${record.y.toLocaleString('it-IT', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`
        },*/
        statistic: {
            title: {
                offsetY: -4,
                customHtml: (container, view, datum) => {
                    const {width, height} = container.getBoundingClientRect();
                    const text = datum ? datum.x : 'Totale';
                    return renderStatistic(width, text, {
                        fontSize: 18,
                    });
                },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '18px',
                },
                customHtml: (container, view, datum, data) => {
                    const {width} = container.getBoundingClientRect();
                    const val = datum ? datum.y : data.reduce((r, d) => r + d.y, 0);
                    const text = val.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    return renderStatistic(width, `€ ${text}`, {
                        fontSize: 18,
                    });
                },
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    };
    return <Spin spinning={loading}>
        <Pie {...config} />
    </Spin>
}