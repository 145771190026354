import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import OrderAccountingSectionItems from "./OrderAccountingSectionItems";
import BackTitle from "../common/BackTitle";
import { emptyOrPlaceholder } from "../util";

export default function AccountingDetails() {
    const { orderId, accountingId } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        return axiosInstance
            .get(`/accounting/${accountingId}`)
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Spin spinning={loading}>
                <BackTitle
                    padding={0}
                    title={`Foglio di contabilità: ${emptyOrPlaceholder(data.name)}`}
                />

                <OrderAccountingSectionItems
                    style={{ marginTop: 10 }}
                    refresh={() => fetchData()}
                    data={data.items ?? []}
                />
            </Spin>
        </div>
    );
}
