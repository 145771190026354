import {useEffect, useRef, useState} from "react";
import {Button, Form, Input, message} from "antd";
import {requiredField} from "../util";
import axiosInstance from "../api";
import {useNavigate, useParams} from "react-router-dom";
import BackTitle from "../common/BackTitle";


export default function NewOrderAccountingSection({onOk}) {
    const [loading, setLoading] = useState();
    const {orderId, accountingId} = useParams();
    const navigate = useNavigate()
    const ref = useRef(null)
    useEffect(() => {
        if(ref)
            ref.current.focus()
    }, []);

    const onFinish = form => {
        setLoading(true)
        axiosInstance.post(`order/${orderId}/accounting/${accountingId}/section`, form)
            .then(() => {
                message.success("Tratta creata")
            })
            .then(onOk)
            .finally(() => setLoading(false))
    }
    return <Form onFinish={onFinish} className={'popover-form'} layout={"vertical"} >
        <Form.Item label={"Nome"} name={"name"}>
            <Input ref={ref}/>
        </Form.Item>
        <Form.Item style={{textAlign: "end", marginBottom: 0}}>
            <Button htmlType={"submit"} type={"primary"} loading={loading}>Salva</Button>
        </Form.Item>
    </Form>
}