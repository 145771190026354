import {Button, Divider, Popover,Typography, Space} from "antd";
import {useContext, useEffect, useState} from "react";
import {LogoutOutlined} from "@ant-design/icons";
import axiosInstance from "../api";
import {keycloakInstance} from "../keycloak";
import {UserContext} from "../App";

const {Text} = Typography;


export default function (){
    const [popoverOpen, setPopoverOpen] = useState(false)
    const userInfo = useContext(UserContext)
    const user = userInfo.user

    const logoutAction = () => {
        keycloakInstance.logout({redirectUri: process.env.REACT_APP_BASE_URL});
    }

    const popoverContent = <Space
        size={1}
        direction={"vertical"}
        style={{
            width: 270,
        }}
    >
        <Space direction={"vertical"} size={0}>
            <Text>{(user?.["firstName"] ?? "") + " " + (user?.["lastName"] ?? "")}</Text>
            <Text type={"secondary"}>{user?.["email"] ?? ""}</Text>
        </Space>
        <Divider dashed={true} style={{marginTop: 15, marginBottom: 15}}/>
        <Button
            style={{minWidth: "100%", textAlign: "start"}}
            icon={<LogoutOutlined/>}
            danger
            type={"text"}
            onClick={logoutAction}
        >
            Logout
        </Button>
    </Space>

    return <Popover
        open={popoverOpen}
        onOpenChange={setPopoverOpen}
        placement="bottomRight"
        content={popoverContent}
        trigger="click">
        <Button
            size="large"
            shape="circle"
            type={"text"}
            style={{backgroundColor: 'coral'}}
            icon={
                <div style={{color: "white"}}>
                    {(user?.["firstName"]?.at(0).toUpperCase() ?? "") +
                        (user?.["lastName"]?.at(0).toUpperCase() ?? "")}
                </div>
            }
        ></Button>
    </Popover>
}