import {Button, Form, Input, message} from "antd";
import {requiredField} from "../util";
import axiosInstance from "../api";
import {useState} from "react";
import BackTitle from "../common/BackTitle";


export default function NewListing(){
    const [loading, setLoading] = useState(false);

    const onFinish = form => {
        setLoading(true)
        axiosInstance.post("listing",form)
            .then(() => {message.success("Listino creato correttamente")})
            .finally(() => setLoading(false))
    }

    return <div style={{display: "flex",flexDirection: "column", rowGap: 16}}>
        <BackTitle padding={0} title={"Nuovo listino"}/>
        <Form onFinish={onFinish} layout={"vertical"}>
            <Form.Item label={"Nome"} name={"name"} rules={[requiredField]}>
                <Input/>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button type={"primary"} htmlType={"submit"} loading={loading}>Salva</Button>
            </Form.Item>
        </Form>
    </div>
}
