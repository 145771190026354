import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../../api";
import {Button, Popconfirm, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, FileExcelOutlined, UploadOutlined} from "@ant-design/icons";
import Toolbar from "../../../common/Toolbar";
import {formatDateTime} from "../../../util";


const  columns = [
    {
        key: 'name',
        dataIndex: 'name',
        width: 150,
        ellipsis: true,
        title: 'Nome'
    },
    {
        key: 'multiplier',
        dataIndex: 'multiplier',
        width: 100,
        ellipsis: true,
        title: 'Coefficiente'
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: 'Creato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'creationDate',
        dataIndex: 'creationDate',
        title: 'Data creazione',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },
    {
        key: 'updatedBy',
        dataIndex: 'updatedBy',
        title: 'Modificato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'updateDate',
        dataIndex: 'updateDate',
        title: 'Data modifica',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },
]

export default function ContractMultipliers(){
    const {id, contractId} = useParams();
    const [multipliers, setMultipliers] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const navigate = useNavigate();


    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`customer/${id}/contract/${contractId}/multiplier`)
            .then(({data}) => setMultipliers(data))
            .finally(() => setLoading(false))
    }, []);


    const deleteSelectedRecords = () => {
    //    setLoading(true)
    }

    return <div>
        <Toolbar
            selectedKeys={selectedRowKeys}
            addAction={() => navigate('multipliers/new')}
            editAction={(k) => navigate(`multipliers/${k}/edit`)}
            deleteAction={deleteSelectedRecords}
        />
        <Table
            loading={loading}
            columns={columns}
            size={"small"}
            scroll={{x: columns.reduce((acc,x)=>acc+x,0)}}
            tableLayout={"fixed"}
            dataSource={multipliers.map(x => ({...x, key: x.id}))}
            pagination={{onChange: () => setSelectedRowKeys([])}}
            rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys}}
        />
    </div>
}
