import {useEffect, useState} from "react";
import axiosInstance from "../../api";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Table} from "antd";
import Toolbar from "../../common/Toolbar";
import {UploadOutlined} from "@ant-design/icons";

const columns = [
    {
        key: 'listingElement',
        dataIndex: 'id',
        title: 'Voce',
        width: 100,
        render: id => id.listingElement.refId
    },
    {
        key: 'category',
        dataIndex: 'id',
        title: 'Categoria',
        width: 200,
        render: id => id.listingElement.category.name
    },
    {
        key: 'description',
        dataIndex: 'id',
        title: 'Descrizione',
        ellipsis: true,
        width: 500,
        render: id => id.listingElement.description,
    },
    {
        key: 'discount',
        dataIndex: 'discount',
        width: 100,
        render: discount => `${discount*100}%`,
        title: 'Sconto'
    }
]
export default function Discounts() {

    const {contractId} = useParams();
    const [discounts, setDiscounts] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`contract/${contractId}/discount`)
            .then(({data}) => setDiscounts(data))
            .finally(() => setLoading(false))
    }, []);

    return <>
        <Toolbar
            extra={(keys) => <>
                    <Button type={"text"} icon={<UploadOutlined/>} onClick={() => navigate('discounts/import')}>Importa</Button>
                </>
            }
        />
        <Table
            loading={loading}
            tableLayout={"fixed"}
            size={"small"}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => {setSelectedRowKeys([record.key])}, // click row
                };
            }}
            rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys}}
            scroll={{x: columns.reduce((acc,x) => acc+x.width,0)}}
            dataSource={discounts.map(x => ({...x, key: x.id.contract.id+"-"+x.id.listingElement.id}))}
            columns={columns}
            />
    </>
}
