import { Button, Popover, Table } from "antd";
import { useParams } from "react-router-dom";
import { DownloadOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import Toolbar from "../common/Toolbar";
import axiosInstance from "../api";
import { downloadFile, withTooltip } from "../util";
import NewSectionItem from "./NewSectionItem";
import EditSectionItem from "./EditSectionItem";
import ImportOrderAccounting from "./ImportOrderAccounting";

const columns = [
    {
        key: "section",
        dataIndex: "section",
        title: "Tratta",
        width: 100,
        fixed: true,
        ellipsis: true,
    },
    {
        key: "refId",
        dataIndex: "listingElement",
        title: "Voce",
        width: 120,
        fixed: true,
        ellipsis: true,
        render: (le) => withTooltip(le?.refId ?? "EXTRA"),
    },
    {
        key: "description",
        dataIndex: "listingElement",
        title: "Descrizione",
        width: 250,
        ellipsis: true,
        render: (le, record) => withTooltip(le?.description ?? record.description),
    },
    {
        key: "quantity",
        dataIndex: "quantity",
        align: "end",
        width: 80,
        ellipsis: true,
        title: "Quantità",
    },
    {
        key: "notes",
        dataIndex: "notes",
        title: "Note",
        width: 170,
        render: withTooltip,
        ellipsis: true,
    },
    {
        key: "team",
        dataIndex: "team",
        title: "Squadra",
        width: 150,
        render: x => withTooltip(x?.name),
        ellipsis: true,
    },

    /*{
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: 'Creato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'creationDate',
        dataIndex: 'creationDate',
        title: 'Data creazione',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },
    {
        key: 'updatedBy',
        dataIndex: 'updatedBy',
        title: 'Modificato da',
        width: 150,
        ellipsis: true,
        render: x => x.firstName + " " + x.lastName
    },
    {
        key: 'updateDate',
        dataIndex: 'updateDate',
        title: 'Data modifica',
        width: 150,
        ellipsis: true,
        render: formatDateTime
    },*/
];

export default function OrderAccountingSectionItems({ style }) {
    const { accountingId } = useParams();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editItemOpen, setEditItemOpen] = useState(false);
    const [newItemOpen, setNewItemOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [importOpen, setImportOpen] = useState(false);
    const [data, setData] = useState(false);

    const tableRef = useRef(null);

    const deleteSelectedRecords = (keys) => {
        setLoading(true);
        axiosInstance
            .delete(`/accountingItem?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(() => setSelectedRowKeys([]))
            .then(() => fetch())
            .finally(() => setLoading(false));
    };

    const fetch = () => {
        setLoading(true);
        axiosInstance
            .get("accountingItem", { params: { accountingId, pageSize: 99999, page: 0 } })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        const tableItems = !!data
            ? data.map((x) => ({ ...x, key: x.id })).sort((a, b) => a.id - b.id)
            : [];
        const scroll = tableItems.length > items.length;
        setItems(tableItems);
        setScroll(scroll);
    }, [data]);

    useEffect(() => {
        if (scroll) {
            tableRef.current.scrollTo({ index: items.length - 1 });
            setScroll(false);
        }
    }, [scroll]);

    return (
        <div {...{ style }}>
            <EditSectionItem
                open={editItemOpen}
                onFinish={() => {
                    fetch();
                    setEditItemOpen(false);
                }}
                itemId={items.filter((x) => x.id === selectedRowKeys[0])[0]?.id}
                onCancel={() => setEditItemOpen(false)}
            />

            <ImportOrderAccounting
                open={importOpen}
                setOpen={setImportOpen}
                onSuccess={() => fetch()}
            />
            <Toolbar
                topDivider={false}
                selectedKeys={selectedRowKeys}
                addAction={() => {}}
                addButton={
                    <Popover
                        trigger={"click"}
                        open={newItemOpen}
                        onOpenChange={setNewItemOpen}
                        placement={"right"}
                        destroyTooltipOnHide
                        content={
                            <NewSectionItem
                                onOk={() => {
                                    fetch();
                                    setNewItemOpen(false);
                                }}
                            />
                        }
                    >
                        <Button type={"primary"} icon={<PlusOutlined />}>
                            Nuova riga
                        </Button>
                    </Popover>
                }
                editAction={(key) => {
                    setEditItemOpen(true);
                }}
                extra={() => (
                    <>
                        <Button
                            type="text"
                            onClick={() => downloadFile(`/accounting/${accountingId}/excelModel`)}
                            icon={<DownloadOutlined />}
                        >
                            Scarica modello excel
                        </Button>
                        <Button
                            icon={<UploadOutlined />}
                            type="text"
                            onClick={() => setImportOpen(true)}
                        >
                            Importa da modello
                        </Button>
                    </>
                )}
                deleteAction={deleteSelectedRecords}
            />
            <Table
                loading={loading}
                size={"small"}
                scroll={{ x: columns.reduce((acc, x) => x.width + acc, 0), y: 500 }}
                tableLayout={"fixed"}
                columns={columns}
                ref={tableRef}
                pagination={false}
                rowClassName={(record, index) =>
                    record.id === selectedRowKeys[0] ? "scroll-row" : ""
                }
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            setSelectedRowKeys([record.key]);
                        }, // click row
                    };
                }}
                rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys }}
                dataSource={items}
            />
        </div>
    );
}
