import dayjs from "dayjs";
import {useEffect, useState} from "react";
import axiosInstance from "../api";
import {useSearchParams} from "react-router-dom";
import {Spin} from "antd";
import {Column} from "@ant-design/plots";

export default function TimeChart(){

    const [data, setData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const today = dayjs().endOf('month')
    const initialRange = [today.subtract(12, 'month'), today]
    const [loading, setLoading] = useState(false)
    const [isStack, setIsStack] = useState(false)

    const config = {
        data,
        animation: false,
        xField: 'x',
        yField: 'y',
        isStack,
        seriesField: isStack ? 'series' : false,
        legend: {
            position: 'top'
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            y: {
                alias: 'Totale',
                formatter: x => `€ ${x.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
            },
            x: {
                alias: 'Mese',
                formatter: x => dayjs(x).format('MMMM YYYY')
            },
        },
        connectedArea: {
            style: (oldStyle, element) => {
                return {
                    fill: 'rgba(0,0,0,0.25)',
                    stroke: oldStyle.fill,
                    lineWidth: 0.5,
                };
            },
        },
    };


    useEffect(() => {
        setLoading(true)
        let entries = [...searchParams.entries()]
        if (!searchParams.get("startDate"))
            entries = [
                ...entries,
                ['startDate', initialRange[0].format("YYYY-MM-DD")],
                ['endDate', initialRange[1].format("YYYY-MM-DD")]
            ]

        let params = entries.map(([k, v]) => `${k}=${v}`).join("&")
        axiosInstance.get(`stats/timeseries?${params}`)
            .then(({data}) => setData(data))
            .finally(()=> {
                setLoading(false)
                setIsStack(searchParams.has('series'));
            })
    }, [searchParams]);

    return <Spin spinning={loading}>
        <Column  {...config} />
    </Spin>
}