import {Button, Form, message, Select} from "antd";
import {filterOption, requiredField} from "../util";
import {useEffect, useState} from "react";
import axiosInstance from "../api";


export default function SelectEmailRecipients({customerId, onFinish}) {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        axiosInstance.get(`contact?customerId=${customerId}&pageSize=999999`)
            .then(({data}) => setContacts(data.data.map(x => ({value: x.id, label: x.fullName}))))
    }, [])


    return <Form className={"popover-form"} onFinish={onFinish} layout={"vertical"}>
        <Form.Item label={"A"} name={"to"} rules={[requiredField]}>
            <Select showSearch filterOption={filterOption} options={contacts}/>
        </Form.Item>

        <Form.Item label={"CC"} name={"ccs"}>
            <Select mode={"multiple"} filterOption={filterOption} options={contacts}/>
        </Form.Item>

        <Form.Item style={{textAlign: "end", marginBottom: 0}}>
            <Button type={"primary"} htmlType={"submit"} loading={loading}>Invia</Button>
        </Form.Item>
    </Form>
}