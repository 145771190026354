import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../api";
import {Button, Form, Input, message, Radio, Spin} from "antd";
import {useForm} from "antd/lib/form/Form";
import {requiredField} from "../../util";
import BackTitle from "../../common/BackTitle";


export default function EditContract(){
    const {id,contractId} = useParams();
    const [initLoading, setInitLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [form] = useForm()


    useEffect(() => {
        setInitLoading(true)
        axiosInstance.get(`customer/${id}/contract/${contractId}`)
            .then(({data}) => form.setFieldsValue(data))
            .finally(() => setInitLoading(false))
    }, []);

    const onFinish = form => {
        setLoading(true)
        axiosInstance.put(`customer/${id}/contract/${contractId}`,form)
            .then(() => {message.success("Contratto modificato")})
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }

    return <Spin spinning={initLoading}>
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle padding={0} title={"Modifica contratto"}/>
            <Form onFinish={onFinish} form={form} layout={"vertical"}>
                <Form.Item name={'name'} label={"Nome"} rules={[requiredField]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Richiedi coefficiente"} name={"requireMultiplier"} rules={[requiredField]}>
                    <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>no</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item style={{ textAlign: "end"}}>
                    <Button htmlType={"submit"} type={"primary"} loading={loading}>Salva</Button>
                </Form.Item>
            </Form>
        </div>
    </Spin>

}